import _ from 'lodash';

/**
 * Translate the given key.
 * @param {string} key - The key for the translation string.
 * @param {object} [replace] - An object containing placeholder replacements.
 * @returns {string} - The translated string.
 */
export function __(key, replace = {}) {
  let translation;
  let translationNotFound = true;

  try {
    translation = key.split('.').reduce((t, i) => t[i] || null, window._translations[window._locale].php);

    if (translation) {
      translationNotFound = false;
    }
  } catch (e) {
    translation = key;
  }

  if (translationNotFound) {
    translation = window._translations[window._locale]['json'][key]
      ? window._translations[window._locale]['json'][key]
      : key;
  }

  _.forEach(replace, (value, key) => {
    translation = translation.replace(':' + key, value);
  });

  return translation;
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="f9a0e5bd-643d-521e-9a00-faafaef20f13")}catch(e){}}();
//# debugId=f9a0e5bd-643d-521e-9a00-faafaef20f13
